<template>
  <section id="welcome">
    <section-header
      header=""
      sub-header="Kuidas me seda teeme?"
      text="Arhiiviteenuse osutamisel lähtume soovist pakkuda koostööpartneritele terviklik lahendus dokumentide edasiseks haldamiseks. Praktikas tähendab see, et iga partneriga lepitakse kokku tema vajadusi arvestav tööde koosseis. Samas on võimalik välja tuua arhiiviteenuse tavapärased tööetapid"
    />
    <v-container
      :mt-5="$vuetify.breakpoint.mdAndUp"
      :pt-5="$vuetify.breakpoint.mdAndUp"
      px-0
      pb-0
    >
      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <v-card
              :color="item.color"
              dark
            >
              <v-card-title 
                class="title"
                v-text="item.header"
              />
              <v-card-text class=" pb-5 pt-3 white text--primary">
                <p 
                  class="body-1 font-weight-light"
                  v-text="item.text"
                />
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-truck-fast',
          header: 'Dokumentide üleandmine',
          text: "Dokumentide üleandmiseks lepitakse poolte vahel kokku sobiv aeg ja regulaarsus. Üleantavate dokumentide vedu teostatakse arhiivi poolt ja dokumenteeritakse saatelehe ja üleandmis-vastuvõtmise aktiga. Dokumentide pakendamine üleandmiseks toimub vastavalt eelnevale kokkuleppele"
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-file-check',
          header: 'Arhiveerimine',
          text: 'Vastuvõetud dokumendid valmistatakse ette edasiseks säilitamiseks arhiivis. Sõltuvalt dokumentide seisukorrast, vajavad üldjuhul dokumendid nii korrastamist kui kirjeldamist. Arhiivis säilitatavate toimikute kohta koostatakse arhiiviloetelu ning toimikud ja arhiivikarbid tähistatakse edasise arvestuse tagamiseks'

        },
        {
          color: 'green lighten-1',
          icon: 'mdi-folder-lock',
          header: 'Säilitamine',
          text: 'Dokumentide säilitamiseks eraldatakse lähtuvalt säilitatavate dokumentide kogusest arhiivihoidlas vajalikus mahus riiulipinda. Dokumentide lisandumisel või dokumentide hävitamiseks eraldamisel säilitatava arhiivikogu maht muutub. Vastavat arvestust peab arhiivi igakuiselt'

        },
        {
          color: 'indigo',
          icon: 'mdi-library',
          header: 'Kasutamine',
          text: 'Arhiivis säilitatavate dokumentide ja neis sisalduva teabe kasutamine on vastava taotluse alusel võimalik kahel viisil - digiteeritud dokumendikoopia edastamise või originaaldokumendi väljastamise teel'

        },
        {
          color: 'teal',
          icon: 'mdi-cellphone-link',
          header: 'Digiteerimine',
          text: 'Dokumentide haldamise ja kasutamise lihtsustamiseks võib paberdokumendid digiteerida. Digiteerimise ja kirjeldamise tulemusena kujuneb digitaalarhiiv, mis võimaldab lihtsustada dokumentide või teabe kasutamisega seotud tööprotsesse ja tagab väärtusliku info kontrollitud juurdepääsu ja säilimise'

        },
        {
          color: 'red',
          icon: 'mdi-folder-remove',
          header: 'Hävitamiseks eraldamine',
          text: 'Säilitustähtaja möödumisel eraldatakse dokumendid hävitamiseks. Doumentide hävitamise kohta koostatakse hävitamisakti kava, mille kinnitab dokumentide omanik. Dokumentide hävitatamine vastab standardi DIN66399 3. taseme (konfidentsiaalsed dokumendid) nõuetele. Dokumentide hävitamine toimub arhiivis selleks kohandatud ruumides. Paberi purustamise tulemusena tekkiv puru suunatakse ümbertöötlusesse'
        }
      ],
    })
  }
</script>
